.container {
  @include breakpoint($big-screen) {
    width: $max-width-center-container;
  }
}

.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after {
  display: none;
}

p {
  margin: 0;

  &.warning {
    color: $light-red;
  }
}

ul {
  padding-left: 0;
}

h2.thinTitle {
  font-size: 27px;
  font-family: 'OpenSans Light', Arial, Helvetica, sans-serif;
  line-height: normal;
}

.mb-20 {
  margin-bottom: 20px;
}

.page-container {
  background-image: url('../../images/bg.jpg');
  background-repeat: repeat-y, repeat-y;
  background-position: center, center;
  background-attachment: fixed, fixed;
  background-size: cover;
}

main {
  padding-bottom: 50px;
  margin-bottom: 20px;
  min-height: 50vh;
}

.flex-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.align-end {
  align-items: flex-end;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.centeredBlock {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content-wrapper {
  background: $white;
}

.modal-image-container {
  max-width: 600px;
  width: 100%;
}

.categories-sidebar {
  border-radius: 4px;
  border: 1px solid $light-gray;
  z-index: 1;
  position: static;

  width: 100%;
  max-width: 325px;
  @include breakpoint_max($laptop) {
    max-width: 260px;
  }
  @include breakpoint_max($screen) {
    top: 200px;
    max-width: 230px;
  }
  @include breakpoint_max($tablet-small) {
    max-width: 180px;
  }
  @include breakpoint_max($mobile) {
    top: 0;
    max-width: 100%;
  }

  &.scrollable {
    position: fixed;
    top: 100px;

    @include breakpoint_max($screen) {
      top: 200px;
    }

    @include breakpoint_max($mobile) {
      position: static;
      top: 0;
    }
  }
  &.nonscrollable {
    position: absolute;
    top: auto;
    bottom: 0;
  }

  li {
    border-bottom: 1px solid $light-gray;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    a {
      color: $gray;
      padding: 8px 12px;
      display: block;
      font-size: $links-font-size;

      &:hover {
        background: $green;
        color: $white;
      }

      &.active {
        background: $green;
        color: $white;
      }
    }
  }
}

.close {
  position: absolute;
  right: 10px;
  top: 15px;
  cursor: pointer;

  &.inverted {
    right: unset;
    left: -40px;
  }
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  visibility: collapse;
  opacity: 0;
  top: 0;
  left: 0;
  cursor: pointer;
  transition: 0.3s;

  .active & {
    visibility: visible;
    opacity: 1;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: $light-gray;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: $light-gray;
}

.order-creation {
  position: relative;

  h1 {
    line-height: 1.2;
    margin-bottom: 20px;
    font-size: 33px;
    padding-left: 15px;
    font-family: $base-font;
  }
  .title-box {
    background: $product-borders;
    color: $black;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 10px 15px;
    border-bottom: 1px solid $light-gray;
    display: flex;
    justify-content: space-between;
    font-size: $middle-font-size;

    a {
      color: $green;
    }
  }
  .form-order {
    padding: 20px 10px;

    .warning {
      color: $light-red;
      position: absolute;
      bottom: -25px;
    }

    .radio-container {
      width: 100%;
      max-width: 400px;
      padding: 0;
    }
  }
  .radio-container {
    padding: 20px 10px;
    align-items: center;

    label {
      font-size: $middle-font-size;
      margin: 0;
    }
  }
  &-footer {
    &-price {
      border: 1px solid $light-gray;
      padding: 10px;
      display: flex;
      justify-content: flex-end;
      font-size: $big-font-size;
      border-radius: 4px;
    }

    &-attention {
      display: flex;
      align-items: center;
      margin: 20px 0;
      background: $light-red;
      border-radius: 4px;
      font-size: $middle-font-size;
      color: $white;
      padding: 10px;

      p {
        margin-left: 15px;
      }
    }

    &-btn {
      display: flex;
      justify-content: flex-end;
    }
  }
  .no-products {
    font-size: $big-font-size;
  }
}

.radio-container {
  display: flex;
}

.required {
  color: $light-red;
  position: absolute;
  left: -10px;
  top: -5px;
}

.btn {
  display: inline-block;
  text-align: center;
  background: $green;
  padding: 0 20px;
  color: $white;
  line-height: 44px;
  font-size: $big-font-size;
  transition: all 0.3s ease;
  cursor: pointer;
  border: none;
  &:hover {
    color: $white;
    text-decoration: none;
    opacity: 0.7;
  }
  &-error {
    background: $warning;
  }
  &.withLoaderInside {
    width: 100%;
    max-width: 300px;
    @include breakpoint_max($mobile) {
      max-width: 100%;
    }
  }
  &.giant {
    line-height: 64px;
  }
}

.desktop-categories {
  @include breakpoint_max($mobile) {
    display: none;
  }
}

.mobileCategories {
  &-close {
    position: relative;
    opacity: 0;
    height: 0;
    z-index: -1;

    ul {
      margin: 0 !important;
    }
  }
}

.animation-fadeIn {
  transition: 0.5s;
}

.userData {
  padding: 20px 0;

  p {
    font-size: $big-font-size;
    margin-bottom: 20px;
  }
}

.tabs {
  display: flex;
  width: 100%;
  border-bottom: 1px solid $green;
  margin-bottom: 30px;

  @include breakpoint_max($mobile) {
    justify-content: space-between;
  }

  &-btn {
    font-size: $big-font-size;
    padding: 10px 40px;
    border: 1px solid $green;
    border-right: none;
    border-bottom: none;
    //border-radius: 4px;
    color: #000;
    cursor: pointer;
    text-align: center;

    @include breakpoint_max($mobile) {
      width: 33.3%;
      font-size: $middle-font-size;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.active {
      background: $green;
      color: #fff;
    }

    &:hover {
      opacity: 0.8;
    }

    &:last-child {
      border-right: 1px solid $green;
    }
  }
  &-content {
    &-block {
      h2 {
        margin-bottom: 30px;
      }
      table {
        margin: 0;
      }
    }
  }
}

.alertContainer {
  div {
    font-size: 16px;
  }
}

.closeableContainer {
  //width: 100%;
  //max-width: 750px;
  //margin: 0 auto;
  //border: 1px solid $warning;
  //padding: 15px;
  //border-radius: 8px;
}

.categoriesList {
  margin-top: 50px;
}

.infoPage-content {
  margin-top: 30px;

  p {
    font-size: $middle-font-size;
    margin-bottom: 10px;
  }

  img {
    margin: 20px 0;
    width: 100%;
  }

  h4 {
    font-size: 18px;
    margin: 20px 0;
  }

  .singleImg {
    text-align: center;

    img {
      width: auto;
      height: auto;
      max-width: 100%;
    }
  }

  ul {
    list-style: disc;
    padding-left: 40px;
    font-size: $middle-font-size;
    margin-bottom: 20px;
  }
}

.formWrapper {
  display: flex;
  flex-direction: column;
  &-title {
    font-size: 18px;
    padding: 7px 0;
    margin-bottom: 20px;
    color: #333;
    border: 0;
    border-bottom: 1px solid #e5e5e5;
    text-align: center;
  }

  &.align-left {
    .input-field {
      justify-content: center;
    }
  }

  &.noLabelForm {
    .input-field {
      flex-direction: column;
    }
  }
}

.map {
  margin: 30px 0;
  &-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  &-item {
    width: calc(100% / 3 - 10px);

    @include breakpoint_max($tablet) {
      width: calc(100% / 2 - 10px);
    }
    @include breakpoint_max($mobile) {
      width: 100%;
    }
  }
}

.goBack {
  font-size: 18px;
}

.show-message {
  background: $green;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
  opacity: 0.9;

  &-content {
    color: white;
    font-size: 20px;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    @include breakpoint_max($mobile) {
      font-size: 12px;
      padding: 10px 0;
    }
  }
}

.partnersBlock {
  display: flex;
  justify-content: center;

  img {
    width: 150px;
  }
}

p[data-f-id='pbf'] {
  display: none;
}
