.alert {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 200;
  background: $white;
  width: 300px;
  text-align: center;
  -webkit-box-shadow: 0 5px 30px -2px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 5px 30px -2px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 30px -2px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 20px;

  strong {
    font-size: $big-font-size;
    display: block;
  }
}

.fallbackBanner {
  background-color: #ccc;
  padding: 20px;
  border: 1px solid #000;
}
