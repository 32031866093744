@font-face {
  font-family: "OpenSans";
  src: url("../../fonts/OpenSans-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "OpenSans Bold";
  src: url("../../fonts/OpenSans-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "OpenSans Light";
  src: url("../../fonts/OpenSans-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}

