.nav {
  background: $darkGreen;
  border-bottom: 1px solid #e2e2e2;
  min-height: 45px;

  ul {
    list-style: none;

    @include breakpoint_max($mobile) {
      display: none;
    }

    li {
      padding-right: 10px;

      &:last-child {
        padding-right: 0;
      }
    }
  }
  a {
    color: $white;
    font-size: $links-font-size;

    @include breakpoint_max($tablet) {
      font-size: $links-mobile-font-size;
    }

    &.login {
      display: flex;
      align-items: center;

      span {
        margin-left: 5px;
      }
    }
  }
}

.logo {
  @include breakpoint_max($mobile-small) {
    width: 30%;
  }

  img {
    width: 200px;
  }
}

header {
  .mobile {
    width: 100%;
    padding-bottom: 10px;
    padding-top: 10px;

    .flex-container {
      .head-search {
        width: 50%;
      }
    }
  }

  .header-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
    background: $white;

    .scrolled {
      position: fixed;
      top: 0;
      margin-right: -15px;
      margin-left: -15px;
      padding-top: 15px;

      background: $white;
      z-index: 100;
      -webkit-box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.2);
      box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.2);

      @include breakpoint_max($mobile) {
        width: 100%;
      }
    }

    .phones {
      color: $phoneIcon;

      &-container {
        display: flex;
        flex-direction: column;

        a {
          color: $phone;
          font-size: $big-font-size;

          @include breakpoint_max($mobile-small) {
            font-size: $middle-font-size;
          }
        }
      }
    }
    .feedback {
      display: inline-block;
      text-align: center;
      background: $green;
      padding: 0 20px;
      color: $white;
      line-height: 44px;
      font-size: $big-font-size;
      transition: all 0.3s ease;
      cursor: pointer;

      @include breakpoint_max($mobile-small) {
        display: none;
      }
    }
  }

  .index-info {
    padding-top: 25px;
    text-align: center;

    p {
      font-family: $bold-font;
      font-size: $middle-font-size;
      color: $black;
      line-height: 20px;

      @include breakpoint_max($mobile-small) {
        font-size: $links-font-size;
      }
    }
  }
  .categoriesList {
    background: $green;
    padding: 0 15px;
    margin-top: 30px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint_max($tablet) {
      padding: 0 5px;
    }

    @include breakpoint_max($mobile) {
      border-radius: 4px;
      height: auto;
    }

    .mobileCategoriesHidden {
      width: 100%;

      ul {
        @include breakpoint_max($mobile) {
          display: flex;
        }
      }

      .categoriesTitle {
        display: flex;
        justify-content: space-between;
        width: 100%;
        color: $white;
        padding: 15px 0;
      }

      ul.categories-sidebar,
      ul.categories-sidebar-children {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 20px;
        @include breakpoint_max($mobile) {
          margin: 0;
          & a {
            font-size: 14px;
            line-height: 1.6;
          }
          & li {
            padding: 5px 0;
          }
        }
      }

      ul.categories-sidebar li a.active {
        background: $darkGreen;
        &.parentElement:after {
          display: none;
        }
      }

      ul.categories-sidebar-children {
        margin: 0;
        li {
          border: none;
        }
      }

      @include breakpoint($mobile) {
        display: none;
      }
    }

    ul {
      width: 100%;
      @include breakpoint_max($mobile) {
        display: none;
      }
      @include breakpoint_max($tablet) {
        //height: 62px;

        li {
          height: 100%;
        }
      }
    }

    img {
      width: 30px;
      object-fit: contain;

      @include breakpoint_max($tablet) {
        width: 18px;
      }
    }
    a {
      color: $white;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
      line-height: 20px;
      font-size: $links-font-size;
      display: flex;
      align-items: center;

      @include breakpoint_max($tablet) {
        font-size: $links-mobile-font-size;
        flex-direction: column;
        line-height: 12px;
        justify-content: space-around;
        height: 100%;
        text-align: center;
      }

      span {
        margin-left: 5px;
      }
    }
  }
  .warn {
    font-size: $links-font-size;
    font-weight: bold;
    text-transform: uppercase;
    padding-top: 20px;
    color: $warning;
    text-align: center;
  }
}

.head-search {
  width: 30%;
  position: relative;

  .search-btn {
    position: absolute;
    top: 0;
    right: 0;
    background: $green;
    height: 100%;
    width: 50px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .MuiCircularProgress-colorPrimary {
      color: $darkGreen;
    }
  }

  .results {
    position: absolute;
    background: $white;
    width: 100%;
    border: 1px solid $light-gray;
    border-radius: 4px;
    overflow: auto;
    max-height: 300px;
    z-index: 1;

    li {
      padding: 5px 15px;
      border-bottom: 1px solid $light-gray;
      cursor: pointer;

      &:hover {
        background: $light-gray;
      }

      &:last-child {
        border: none;
      }
    }

    &.recent {
      li {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      p {
        padding: 5px 15px;
        font-size: 16px;
        width: 100%;
      }

      div {
        display: flex;
        align-items: center;
        height: 30px;
        width: 50px;
        justify-content: center;
      }
    }
  }

  span {
    margin-top: 7px;
    color: $white;
  }
}

.bm-menu {
  background: $green;
}

.bm-menu-wrap {
  top: 0;
  left: 0;
}
.bm-overlay {
  top: 0;
  left: 0;
}
