.article {
  display: flex;

  @include breakpoint_max($mobile) {
    flex-direction: column;
  }
  
  & > div {
    flex: 1;
  }
  &-image-container {
    padding-right: 30px;
    @include breakpoint_max($mobile) {
      padding-right: 0;
    }
  }
  &-image {
    width: 100%;
    object-fit: cover;
  }
  & h1,
  h2 {
    margin-bottom: 15px;
    color: $black;
    line-height: normal;
  }
  & h1 {
    font-size: $middle-title-font-size;
  }
  & h2 {
    font-size: $price-font-size;

    &.extended {
      font-size: $page-title;
    }
  }
  &-description {
    font-size: $font-size-base;
    color: $light-black;
  }
  & a {
    text-decoration: none;
  }
  & a span {
    margin-top: 15px;
    display: inline-block;
    text-align: center;
    background: $green;
    padding: 0 20px;
    color: $white;
    line-height: 44px;
    font-size: $big-font-size;
    transition: all 0.3s ease;
    &:hover,
    &focus {
      background: $darkGreen;
    }
  }
}
.decoration {
  &-line {
    width: 60px;
    height: 3px;
    background-color: $black;
  }
}
.articleItem {
  padding: 50px 0;

  &-withoutImage {
    align-items: center;
  }

  &-reverse {
    flex-direction: row-reverse;
    @include breakpoint_max($mobile) {
      flex-direction: column;
    }
  }
  &-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include breakpoint_max($mobile) {
      margin-top: 30px;
    }

    &.pr {
      padding-right: 30px;
      @include breakpoint_max($mobile) {
        padding-right: 0;
      }
    }
    &.pl {
      padding-left: 30px;
      @include breakpoint_max($mobile) {
        padding-left: 0;
      }
    }
  }
  &-image {
    position: relative;
    width: 95%;
    height: 500px;
    z-index: 2;
    object-fit: cover;

    &-wrapper {
      position: relative;

      &.reverse {
        display: flex;
        justify-content: flex-end;
      }
    }
    &-bg {
      position: absolute;
      width: 50%;
      height: 80%;
      right: 0;
      top: 10%;
      background-color: $green;

      &.reverse {
        right: unset;
        left: 0;
      }
    }
  }
}

.articlePost {
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  padding: 50px 0;

  & li {
    margin-left: 30px;
    list-style: disc;
  }

  @include breakpoint_max($mobile) {
    width: 100%;

    & p,
    li {
      font-size: $font-size-base;
    }
  }
}
