.slick-slider {
  border-top: 5px solid $green;
  padding: 15px;

  &.noEffects {
    padding: 0;
    border: none;
  }
  &.border-padding {
    border: none;
    padding: 0 20px;
  }
  .slick-arrow.slick-prev:before,
  .slick-arrow.slick-next:before {
    color: $green;
    display: block;
  }
}

button.slick-arrow {
  z-index: 100;
  background: none;
  font-size: 0;
  padding: 0;

  &.slick-prev {
    left: -10px;
  }
  &.slick-next {
    right: -10px;
  }
}

button.slick-arrow:hover,
button.slick-arrow:active,
button.slick-arrow:focus {
  background: none;
}
