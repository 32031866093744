.basket {
  &-btn {
    background-color: $green;
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.35);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 45px;
    font-family: $bold-font;
    color: $white;
    font-size: 13px;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.196);
    border-radius: 50em;
    cursor: pointer;

    .icon {
      margin-right: 10px;
      margin-top: 5px;
    }
  }
  &-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 200;
    top: 0;
    left: 0;
    visibility: collapse;

    .active & {
      visibility: visible;
    }
  }
  &-view {
    width: 400px;
    height: 100%;
    position: absolute;
    right: -450px;
    background: $white;
    z-index: 300;
    -webkit-box-shadow: 0 5px 30px -2px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 5px 30px -2px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 30px -2px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.3s;
    visibility: visible;

    @include breakpoint_max($mobile-small) {
      width: 80%;
      right: -90%;
    }

    .active & {
      right: 0;
    }
  }
  &-content {
    height: calc(100% - 66px - 90px);
  }
  &-list {
    overflow: auto;
    height: 100%;
  }
  &-buy {
    &-info {
      display: flex;
      background: $green;
      padding: 10px;
      position: relative;
      justify-content: space-between;
      align-items: flex-end;

      .count {
        font-family: $bold-font;
        color: $black;
        font-size: 26px;
        position: absolute;
        left: 26px;
        top: 42px;
        width: 38px;
        text-align: center;
      }
      .price {
        font-family: $bold-font;
        color: $black;
        font-size: 26px;
        margin-bottom: 8px;
        padding-right: 10px;

        @include breakpoint_max($mobile-small) {
          font-size: $upper-font-size;
        }
      }
    }
    &-btn {
      background: $green;
      padding: 30px 0;
      text-align: center;
      font-family: $bold-font;
      color: $white;
      font-size: $price-font-size;

      @include breakpoint_max($mobile-small) {
        font-size: $big-font-size;
      }
    }
  }
  &-loading {
    display: flex;
    padding-top: 50px;
    justify-content: center;
  }
}

.basketItem {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid $light-gray;

  &-img {
    width: 110px;
    height: 110px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &-info {
    width: 100%;
    padding-left: 20px;
  }
  &-desc {
    display: flex;
    justify-content: space-between;

    a {
      color: $green;

      &:hover {
        color: $gray;
      }
    }
  }
  &-remove {
    padding-left: 10px;
    cursor: pointer;
  }
  &-actions {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-price {
    font-size: $big-font-size;
    display: flex;
    flex-direction: column;

    span {
      font-size: $links-font-size;
      @include breakpoint_max($mobile-portrait) {
        font-size: $links-mobile-font-size;
      }
    }
  }
  &-calc {
    display: flex;

    .count {
      margin-top: -3px;
      padding: 0 10px;
      font-size: $big-font-size;
      @include breakpoint_max($mobile-portrait) {
        padding: 0 5px;
      }
    }

    span {
      display: flex;
      align-items: center;
      cursor: pointer;
      user-focus: none;
    }
  }
}
