*, *:before, *:after {
	box-sizing: border-box;
	word-wrap: break-word;
}

html{
	background: $white;
	height:100%;

	&.with-featherlight{
		overflow: hidden;
	}
}

body{
	height: 100%;
	font-size: 14px;
	line-height: 20px;
	color: $middle-blue;
	width:100%;
	font-weight: normal;
	min-width:$base-min-width;
	font-family: $base-font;
	-webkit-text-size-adjust:100%;
	-ms-text-size-adjust: none;
	background: $white;
	position:relative;

	@include breakpoint($mobile) {
		font-size: 12px;
		line-height: 26px;
	}
}

html, body, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
}

strong {
	font-family: $bold-font;
}

img{
	border-style:none;
	vertical-align: top;
	outline:none;
	height:auto;
	max-width:100%;
}

a {
	text-decoration:none;
	color: $link-color;
	outline: none;
	transition: color 0.2s linear;

	&:hover{
		outline:none;
		text-decoration: underline;
	}

	&:focus{
		outline: none;
		text-decoration: underline;
		color: $link-color;
	}
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
	display: block;
}

ul,ol{
	list-style:none;
	margin:0;
	padding:0;
}

table > thead > tr > th,
table > thead > tr > td,
table > tbody > tr > th,
table > tbody > tr > td,
table > tfoot > tr > th,
table > tfoot > tr > td{
	border:none;
}

form, fieldset {
	margin:0;
	padding:0;
	border-style:none;
}

select{
	border: 1px solid $link-color;
	padding: 5px;
	outline: none;
	width:100%;

	&:focus,
	&:hover{
		outline: none;
	}
}

button::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	padding:0;
	border:0;
}

input[type="radio"],
input[type="checkbox"] {
	outline: none;
	padding: 0;
}

/* Base for label styling */
// [type="checkbox"]:not(:checked),
// [type="checkbox"]:checked {
//   position: absolute;
//   left: -9999px;
// }

button[type="button"],
button[type="submit"],
input[type="button"],
input[type="submit"] {
	background: $green;
	font-family: $base-font;
	font-size: 15px;
	line-height: 48px;
	//                                                                                                                                                             
	color: $white;
	outline: none;
	cursor: pointer;
	border: none;
	margin: 0;
	padding: 0 10px;
	text-decoration: none;
	-webkit-appearance: none;
}

input[type="search"],
input[type="email"] {
	-webkit-appearance: none;
	border-radius:0;
	box-shadow: none;
	-webkit-box-shadow: none;
	outline: none;
}

textarea,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="tel"],
input[type="url"],
input[type="search"]{
	font-size: 16px;
	line-height: 20px;
	outline: none;
	width: 100%;
	background: $white;
	color: $middle-blue;
	box-sizing: border-box;
	height: 34px;
	display: inline-block;
	vertical-align: top;
	padding: 5px 20px;
	margin: 0;
	border: 1px solid #ccc;
	transition:all 0.2s linear;
	-webkit-appearance: none;
	border-radius: 8px;
	font-family: $base-font;

	&:focus{
		border-color: $link-color;
		//border-width: 2px;
		//box-shadow: inset 0 0 0px 1px $input-border-color;
		outline: none;

		@include placeholder {
			color: #a5a7b8;
		}
	}

	@include placeholder {
		color: $middle-blue;
	}
}

textarea{
	border: 1px solid #ccc;
	min-height: 150px !important;
	max-height: 300px !important;
	padding: 5px 20px;
	display: block;
	width: 100%;
	max-width: 100%;
	font-size:18px;
	line-height:32px;
	color: $middle-blue;
	background: $white;
	transition:all 0.2s linear;
	letter-spacing: 0;
	font-family: $base-font;
	resize:none;

	@include placeholder {
		color: $middle-blue;
	}
}

label{
	font-weight: normal;
	display: block;
	margin: 0 0 6px;
	padding: 0 8px;
	font-size: 18px;
	line-height: 20px;
}

table{
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	text-align: left;

	th{
		font-weight: normal;
	}
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
}

blockquote{
	border:none;
	line-height:34px;
}

q {quotes: none;}
q:before,
q:after {
	content: '';
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	color: #E63734;
}

sup {top: -0.5em;}
sub {bottom: -0.25em;}

p{
	margin: 0 0 12px;
}

address{
	margin-bottom: 12px;
	font-style: normal;
}
