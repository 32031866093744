.input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-bottom: 20px;

  & input {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition:
      border-color ease-in-out 0.15s,
      box-shadow ease-in-out 0.15s;
    outline: none;

    .head-search & {
      height: 40px;
      line-height: 20px;
      padding: 0 10px;
      background: #f7f7f7;
      font-size: 16px;
    }
  }
  & .input-field.error {
    border: 1px solid $light-red;
  }
  & input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='number'],
  input[type='tel'],
  input[type='url'],
  input[type='search'] {
    font-size: 16px;
    line-height: 20px;
    outline: none;
    width: 100%;
    background: $white;
    color: $middle-blue;
    box-sizing: border-box;
    height: 34px;
    display: inline-block;
    vertical-align: top;
    padding: 5px 20px;
    margin: 0;
    border: 1px solid #ccc;
    transition: all 0.2s linear;
    -webkit-appearance: none;
    border-radius: 8px;
    font-family: $base-font;

    &:focus {
      border-color: $link-color;
      outline: none;

      @include placeholder {
        color: #a5a7b8;
      }
    }

    @include placeholder {
      color: $middle-blue;
    }
  }

  & input[type='button']:hover,
  input[type='submit']:hover,
  input[type='button']:focus,
  input[type='submit']:focus {
    background: $btn-bg-hover;
  }

  & input[type='button'],
  input[type='submit'] {
    background: $green;
    font-family: $base-font;
    font-size: 15px;
    line-height: 48px;
    transition: 0.3s ease-in-out all;

    color: $white;
    outline: none;
    cursor: pointer;
    border: none;
    margin: 0;
    padding: 0 10px;
    text-decoration: none;
    -webkit-appearance: none;

    &:focus,
    &:hover {
      background: $btn-bg-hover;
      opacity: 0.7;
    }
  }
  & input[type='radio'],
  input[type='checkbox'] {
    outline: none;
    padding: 0;
  }
  & .input-error {
    color: $light-red;
  }

  & .input-label {
    position: relative;
    font-size: $big-font-size;
    flex: 1;
    @include breakpoint_max($mobile) {
      margin-right: 10px;
    }
  }
}
.input {
  &-body {
    flex: 4;
    width: 100%;
    @include breakpoint_max($mobile-small) {
      flex: 3;
    }
    @include breakpoint_max($mobile-portrait) {
      flex: 2;
    }
  }
}
