.form-wrapper {
  padding: 15px;
  .title {
    font-size: 22px;
    border-bottom: 1px solid #e5e5e5;
    padding: 7px 0;
    margin-bottom: 30px;
  }

  .warning {
    position: absolute;
    bottom: -25px;
    right: 0;

    @include breakpoint_max($mobile-small) {
      bottom: -30px;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;

    @include breakpoint_max($mobile-small) {
      flex-direction: column;

      button:first-child {
        margin-bottom: 20px;
      }
    }
  }
}
