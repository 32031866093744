table {
  border: 1px solid $light-gray;
  border-collapse: collapse;
  text-align: center;

  @include breakpoint_max($mobile) {
    border-collapse: collapse;
    overflow-x: auto;
    width: fit-content;
    max-width: 100%;
  }

  thead {
    font-family: $bold-font;
    border-bottom: 1px solid $light-gray;
    font-size: $middle-font-size;

    tr {
      th {
        padding: 10px;
        border: 1px solid $light-gray;
      }
    }
  }

  img {
    width: 100px;
  }

  tbody {
    font-size: $links-font-size;

    tr {
      td {
        padding: 10px;
        border: 1px solid $light-gray;
      }
      &:hover {
        background: #fbf8f8;
      }

      th {
        padding: 10px;
        font-family: $bold-font;
        font-size: $middle-font-size;
      }
    }

  }
  .total {
    font-size: $big-font-size;
  }
  .action-box {
    border: 1px solid $white;
    width: 30px;
    padding: 0;
  }
  .basketItem-calc {
    justify-content: center;
  }

  &.inlineTable {
    display: flex;
    flex-direction: row;

    thead {
      min-width: 200px;
    }

    tr {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    tbody {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    td, th {
      height: 50px;
      overflow: auto;
    }
    th:last-child {
      border-bottom: none;
    }
    .imageField {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      img {
        width: 100%;
        max-height: 180px;
      }
    }
  }
}

.scrollableTable {
  height: 100%;
  width : 100%;
  overflow : scroll;
  margin: 50px 0;
}
