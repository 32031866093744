.modal {
  position: absolute;
  width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  outline: none;

  @include breakpoint_max($mobile) {
    width: 70%;
  }

  &-title {
    padding: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid $product-borders;
    text-align: center;

    h3 {
      font-size: $price-font-size;
      @include breakpoint_max($mobile) {
        font-size: $big-font-size;
      }
    }
  }
  &-body {
    padding: 15px;
    margin-bottom: 30px;

    button {
      outline: none;
    }
  }
}
