.auth-page {
  .form {
    .input-field {
      flex-direction: column;
      align-items: flex-start;
    }
    .input-body {
      width: 100%;
    }
  }
  .signup,
  .login {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
  }
  h2 {
    font-size: 27px;
    font-family: 'OpenSans Light', Arial, Helvetica, sans-serif;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 20px;
  }
}
