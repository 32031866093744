textarea.input {
  display: block;
  width: 100%;
  height: 34px;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  outline: none;

  .head-search & {
    background: #f7f7f7;
    font-size: 16px;
  }
}
