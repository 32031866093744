footer {
  background-color: $footer-color;
  font-family: $light-font;

  .container {
    padding-top: 30px;
  }

  ul {
    padding-top: 10px;

    li {
      padding-bottom: 5px;

      a, p {
        color: $low-opacity-white;
        font-size: $links-font-size;

        &:visited, &:focus {
          color: $low-opacity-white;
          text-decoration: none;
        }

        &:hover {
          color: $white;
          text-decoration: underline;
        }
      }
    }
  }

  .copyRights {
    border-top: 1px solid $white;
    padding-top: 20px;
    color: $dark-gray;
    font-size: $links-font-size;
    line-height: 40px;
  }
  .socials {
    margin-top: 10px;

    &-title {
      font-size: $font-size-base;
      color: $white;
      text-transform: uppercase;
    }
    &-links {
      display: flex;

      a {
        margin-right: 10px;
      }

      .footer-contacts-icon {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.footer {

  &-title {
    color: $white;
    text-transform: uppercase;
    font-size: $upper-font-size;
    border-bottom: 1px solid $white;
    padding-bottom: 5px;
  }
  &-contacts {

    li {
      display: flex;
      align-items: center;
    }

    a, p {
      padding-left: 10px;
    }
  }
}

.footer-contacts-icon {
  background: $green;
  border-radius: 4px;
  width: 25px;
  height: 25px;
  position: relative;

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: $white;
  }
}