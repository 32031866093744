.pagination {
  &-container {
    display: flex;
    gap: 10px;

    justify-content: center;
    align-items: center;
  }
  &-item {
    color: $green;
    border-radius: 3px;
    &.active {
      background-color: $green;
      color: white;
    }
    &.disabled {
      display: none;
    }
  }
  &-link {
    color: inherit;
    padding: 10px;
    text-decoration: none;
    &.active {
      color: white;
    }
  }
}
