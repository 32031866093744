.reg-page {
  .title {
    h2 {
      font-size: 27px;
      font-family: 'OpenSans Light', Arial, Helvetica, sans-serif;
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 20px;
    }
  }
}
