.products {
  margin-top: 20px;

  h1 {
    font-size: $page-title;
    padding-left: 15px;
    padding-bottom: 20px;
    border-bottom: 1px solid $title-gray;
    color: $light-black;
    font-family: $light-font;
    margin-bottom: 20px;
    line-height: 30px;
  }
  &-options {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: $links-font-size;
    align-items: center;

    @include breakpoint_max($tablet) {
      flex-direction: column;
    }

    &-sort {
      display: flex;

      @include breakpoint_max($mobile-small) {
        flex-direction: column;
        height: 100px;
        justify-content: space-between;
      }
    }
    &-compare {
      a {
        color: $brown;
        font-size: $links-font-size;
      }
      @include breakpoint_max($tablet) {
        margin-bottom: 20px;
      }
    }
    &-select {
      display: flex;
      align-items: center;
      background: $title-gray;
      border-radius: 3px;
      justify-content: space-between;

      &:first-child {
        margin-right: 20px;

        @include breakpoint_max($mobile-small) {
          margin-right: 0;
        }
      }

      label {
        font-size: $links-font-size;
        margin-bottom: 0;
        user-select: none;
      }

      select {
        width: 150px;
        border-radius: 3px;
        border: 1px solid $light-gray;
        border-left: none;
      }
    }
  }
  &-pagination {
    margin-top: 30px;
  }
}

.product {
  &-layout {
    border: 1px solid $light-gray;
    // margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: auto;
    border-radius: 3px;

    a {
      display: block;
    }
  }
  &-image {
    margin: 20px 0;
    padding: 0 10px;

    img {
      object-fit: cover;
      height: 228px;
    }

    &:hover {
      opacity: 0.8;
    }
  }
  &-caption {
    padding: 0 20px;
    min-height: 90px;

    h4 {
      font-size: $title-font-size;
      font-family: $bold-font;
      line-height: 1.1;
      margin-bottom: 10px;

      a {
        color: $green;

        &:hover {
          color: $gray;
        }
      }
    }
  }
  &-price {
    font-family: $bold-font;
    color: $light-black;
  }
  &-buttonGroup {
    border-top: 1px solid $light-gray;
    background-color: $green;
    display: flex;

    button,
    a {
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $green;
      color: $white;
      line-height: 38px;
      font-family: $bold-font;
      text-align: center;
      text-transform: uppercase;
      outline: none;

      &:hover {
        background-color: $hover-green;
        text-decoration: none;
        cursor: pointer;
      }
    }

    &-mini {
      width: 20%;
      border-left: 1px solid $light-gray !important;

      svg {
        margin-left: -1px;
      }
    }

    &-buy {
      width: 80%;

      svg {
        margin-right: 15px;
        margin-top: -4px;
      }
    }
  }
  &-page {
    display: flex;
    align-items: flex-start;
    margin-right: 15px;
    margin-left: 15px;
    border: 1px solid $product-borders;
    border-bottom: 10px solid $product-borders;
    border-top: 10px solid $product-borders;
    border-radius: 4px;
    padding: 0 15px 150px 0;
    position: relative;
    @include breakpoint_max($mobile-small) {
      padding-right: 0;
    }

    @include breakpoint_max($mobile) {
      flex-direction: column;
    }

    &-view {
      width: 70%;
      @include breakpoint_max($mobile) {
        width: 100%;
      }
      &-description {
        width: 100%;
        padding: 30px;
        max-height: 500px;
        overflow: auto;

        @include breakpoint_max($mobile) {
          max-height: 200px;
        }

        & ul,
        & ol {
          list-style: circle;
          padding-left: 40px;
        }
      }

      &-title {
        color: #404040;
        text-decoration: none;
        font-size: 20px;
        margin-top: 0;
        margin-bottom: 20px;
        font-weight: 400;
        padding-top: 10px;
        padding-left: 15px;
      }
      &-info {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 20px;
        @include breakpoint_max($mobile-small) {
          flex-direction: column;
        }

        & div {
          @include breakpoint_max($mobile-small) {
            width: 100%;
          }

          &.desc {
            padding-left: 25px;
          }

          &.img {
            text-align: center;
            max-width: 300px;

            &-popup {
              margin: 0 auto;
              cursor: pointer;
            }
          }

          &.desc-options {
            font-size: $title-font-size;
            line-height: 25px;
            width: 100%;

            span {
              color: $green;
            }
          }
          &.desc-price {
            padding-top: 60px;

            span {
              color: $red;
              font-size: $price-font-size;
              display: block;

              svg {
                padding-top: 5px;
                margin-right: 10px;
              }
            }
            h2 {
              font-size: $price-title;
              color: $light-black;
              font-weight: 400;
              margin-top: 5px;
              line-height: 30px;
            }
          }
        }
        & .additionalOptions {
          border-top: 1px solid $title-gray;
          margin-top: 40px;
          padding-top: 50px;
          width: 100%;
        }
      }
    }
    &-options {
      width: 30%;
      margin-top: 80px;
      position: relative;
      @include breakpoint_max($mobile) {
        width: 100%;
      }
    }
    & .warnings {
      text-align: center;
      color: $light-red;
      font-size: $big-font-size;
      margin-top: 30px;
      @include breakpoint_max($mobile-small) {
        width: 100%;
      }
    }
    &-buy {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      @include breakpoint_max($mobile-small) {
        flex-direction: column;
      }

      &-buttonBlock {
        display: flex;
        flex-direction: column;

        p {
          text-align: center;
        }

        & .actionsBlock {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 20px;
        }
      }

      & .basketItem-calc {
        margin-top: 20px;
        margin-right: 40px;
        @include breakpoint_max($mobile-small) {
          justify-content: center;
          align-items: center;
          margin-bottom: 30px;
          margin-right: 0;
        }
      }
      & .btn-product-add-wishlist {
        appearance: none;
        background: $green;
        border: none;
        border-radius: 9999px;
        display: flex;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;

        & > svg {
          color: $white;
        }
      }
      & .btn-product-buy {
        width: 180px;
        padding: 20px 14px;
        background: red;
        border: 0;
        color: #ffffff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        font-size: 15px;
        line-height: 1.3333333;
        min-height: 59px;

        &-loading {
          padding-top: 0;
          padding-bottom: 0;
          color: $white;

          .progressBar {
            width: 20px;
            height: 20px;
          }
        }
        &-disabled {
          background-color: $light-gray;
          text-shadow: none;
          cursor: default;
        }
      }
    }
  }
}

.options {
  &-title {
    text-transform: uppercase;
    text-align: center;
    border-bottom: 1px solid $light-gray;
    font-size: $middle-font-size;
    padding-bottom: 5px;
    position: relative;
    margin-bottom: 30px;

    &:before,
    &:after {
      content: '';
      right: 50%;
      margin: 0 -8px 0 0;
      position: absolute;
      display: block;
      height: 12px;
      width: 12px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    &:after {
      bottom: -5px;
      background-color: #fff;
      overflow: none;
    }
    &:before {
      bottom: -7px;
      background-color: #e7e7e7;
    }
  }
  &-list {
    .pharmacies {
      max-height: 560px;
      overflow: auto;
      padding-left: 20px;
    }

    .units {
      padding-left: 20px;
    }

    & .input {
      &-field {
        flex-direction: column;
        align-items: flex-start;
      }

      &-label {
        font-size: 16px;
        padding-bottom: 10px;
        margin-left: 10px;
        color: $phone;
        line-height: 20px;
        position: relative;
      }
    }
    & .radio-container {
      padding: 10px 0 20px 0;
      position: relative;
      width: 100%;

      & input {
        visibility: hidden;

        & + label:after,
        & + label:before {
          background: #fff;
          box-shadow:
            0 0 0 2px #fff,
            0 0 0 4px green;
        }

        & + label:after {
          content: '';
          display: block;
          height: 4px;
          width: 24px;
          position: absolute;
          top: 17px;
          left: 0;
          -webkit-transition: ease-out 0.3s;
          transition: ease-out 0.3s;
        }

        & + label:before {
          content: '';
          display: block;
          height: 24px;
          width: 4px;
          position: absolute;
          top: 7px;
          left: 10px;
          -webkit-transition: ease-out 0.3s;
          transition: ease-out 0.3s;
        }

        &:checked + label:after,
        &:checked + label:before {
          background: green;
          box-shadow:
            0 0 0 2px #fff,
            0 0 0 4px green;
        }

        &:checked + label span.fix {
          background: green;
        }
      }

      & label {
        margin-bottom: 0;
        font-size: $font-size-base;
        width: 100%;
        cursor: pointer;

        & .price {
          width: 100%;
          display: block;
          text-align: right;
          font-size: $links-font-size;
          color: $middle-green;
          font-family: $bold-font;
        }
      }
    }
  }
}

div.cart-inside {
  margin: 0 auto;
  padding: 0;
  position: relative;
  text-align: center;
  display: table;
  width: 100%;
  @include breakpoint_max($mobile) {
    max-width: 290px;
    margin-bottom: 20px;
  }

  & .wishList {
    display: inline-block;
    bottom: 15px;
    left: 30px;
    width: auto;

    .w1 {
      color: $light-black;
      left: 2px;
      position: absolute;
      top: -2px;
    }
    .w2 {
      left: 29px;
      position: absolute;
      top: -15px;
      background: url(../../images/arrowproductcart.png) 0 0/100% 100% no-repeat;
      width: 55px;
      height: 74px;
      display: block;
      -webkit-transform: rotate(130deg);
      transform: rotate(130deg);
    }
    .w3 {
      cursor: pointer;
      padding-top: 6px;
      margin: 13px 5px 10px;
      border-radius: 5px;
      height: 32px;
      width: 32px;
      background: $green;
      color: $white;
      display: block;
      transform: rotate(45deg);
      box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.35);

      svg {
        transform: rotate(-45deg);
      }
    }
  }
  .compare {
    display: inline-block;
    bottom: 15px;
    left: 160px;
    width: auto;

    .c1 {
      color: $light-black;
      right: -6px;
      position: absolute;
      top: 36px;
    }
    .c2 {
      right: 29px;
      position: absolute;
      top: -6px;
      background: url(../../images/arrowproductcart.png) 0 0/100% 100% no-repeat;
      width: 55px;
      height: 74px;
      display: block;
      transform: rotate(-50deg);
    }
    .c3 {
      cursor: pointer;
      padding-top: 6px;
      margin: 13px 5px 10px;
      border-radius: 5px;
      height: 32px;
      width: 32px;
      background: $green;
      color: $white;
      display: block;
      transform: rotate(45deg);
      box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.35);

      svg {
        transform: rotate(45deg);
      }
    }
  }
}

.cro {
  height: 30px !important;
  width: 30px !important;
  cursor: pointer;

  & .fix,
  & .fix2 {
    position: absolute;
    display: block;
    width: 4px;
    height: 14px;
    left: 10px;
    z-index: 1;
    top: 11px;
  }
  & .fix {
    transition: ease-out 0.3s;
    background: #fff;

    &:before,
    &:after {
      position: absolute;
      width: 2px;
      height: 2px;
      background: #fff;
      z-index: 1;
      content: ' ';
      display: block;
      -webkit-transition: ease-out 0.3s;
      transition: ease-out 0.3s;
    }
    &:before {
      left: -2px;
      top: 2px;
    }
    &:after {
      left: 4px;
      top: 2px;
    }
  }
  & .fix2:before,
  & .fix2:after {
    position: absolute;
    width: 2px;
    height: 2px;
    background: #fff;
    z-index: 1;
    content: ' ';
    display: block;
    -webkit-transition: ease-out 0.3s;
    transition: ease-out 0.3s;
  }
  & .fix2:before {
    left: -2px;
    bottom: 0;
  }
  & .fix2:after {
    left: 4px;
    bottom: 0;
  }
}
.equal {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -15px;
}
.equal > [class*='col-'] {
  margin-bottom: 15px;
}

.unit-name {
  text-transform: capitalize;
}
